<template>
  <div class="awardBook_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
      <van-tabs v-model="active" animated title-inactive-color="#000000" title-active-color="#3abaf7" line-width="42%" line-height="3px" @click="onTabClick">
        <van-tab v-for="(item, index) in tab" :key="index" :title="item.name"></van-tab>
      </van-tabs>
    </div>

    <ol class="list_box">
      <van-list ref="list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <li class="list van-hairline--bottom" v-for="(item, index) in list" :key="index" @click="goPage('awardBookDetail?key=' + item.pbId)">
          <p class="name">{{item.pbTitle}}</p>
          <van-image class="photo" fit="cover" width="100%" height="70vw" :src="item.headImgUrl" />
          <!-- <p class="txt">{{item.author}}&nbsp;&nbsp;&nbsp;{{item.pbDate | setDate}}</p> -->
        </li>
      </van-list>
    </ol>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
  components: {
    vHeader
  },
  data() {
    return {
      title: this.$route.meta.title,
      active: 0,
      tab: [
        {
          name: '排列五',
          value: '5'
        },
        {
          name: '七彩星',
          value: '7'
        }
      ],
      list: [],
      loading: false,
      finished: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      showHeader:true
    }
  },
  filters: {
    setDate(vle) {
      if (vle) { return `${vle.substr(5, 2)}月${vle.substr(8, 2)} ${vle.substr(11, 5)}` }
      return '';
    }
  },
  created() {
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
  },
  methods: {
    onTabClick() {
      console.log('');
      this.loading = false
      this.finished = false
      this.pagination.pageNo = 1;
      this.list = []
      setTimeout(() => {
        this.$refs.list.check();
      }, 100);
    },
    async onLoad() {
      const res = await this.$HTTP.post(this, 'API_PRIZE_BOOK_LISTS', { lotteryKind: this.tab[this.active].value, pageNo: this.pagination.pageNo, pageSize: this.pagination.pageSize }, {}) || []
      this.loading = false;
      this.pagination.pageNo += 1;
      console.log('res', res);
      this.list = this.list.concat(res.data)
      // 数据全部加载完成
      if (this.list.length >= res.totalSize * 1) {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less">
.awardBook_page {
  .van-tabs__line {
    background-color: #3abaf7;
  }
  .list_box {
    background-color: white;
    .list {
      width: 82%;
      margin: auto;
      padding: 10px 0;
      .name {
        margin: 0;
        line-height: 3em;
        font-size: 18px;
      }
      .photo {
        border-radius: 2px;
        overflow: hidden;
      }
      .txt {
        font-size: 14px;
        color: #999;
        margin: 0.5em 0;
      }
    }
  }
}
</style>
